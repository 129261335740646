import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import SeminarView from '../views/SeminarView.vue'
import SeminarDetails from '../views/SeminarDetails.vue'
import ContactView from '../views/ContactView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  } ,
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  } ,
  {
    path: '/seminars',
    name: 'Seminars',
    component: SeminarView,
    props: true
},
{
  path: '/seminar/:id',
  name: 'SeminarDetails',
  component: SeminarDetails,
  props: true
}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
