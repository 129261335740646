<template>
Have a question that needs answering? Drop us a line below:
<div class="contact">
   <form @submit.prevent="subForm">
       <label for="fname">Full Name: </label>
       <input type="text" id="fname" v-model="fullname">
       <div v-if="nameError" class="error">{{ nameError }}</div>
       <label for="myemail">Email: </label>
       <input type="email" id="myemail" required v-model="email">
       <label for="mytext">Your Question: </label>
       <textarea id="mytext" rows="5" v-model="your_question" cols="45">Your question...</textarea>
       <div v-if="questionError" class="error">{{ questionError }}</div>
       <div class="btn">
           <button>Submit</button>
       </div>
   </form>
       <div v-if="no_errors" class="success">
        You Successfully submitted the form
   <p>Your full name: {{ fullname }}</p>
   <p>Your email: {{ email }}</p>
   <p>Your Question: {{ your_question }}</p>
   </div>
   </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            nameError: '',
            fullname: '',
            questionError: '',
            no_errors: '',
            your_question: ''
        }
    },
    methods: {
        subForm() {
            this.nameError = this.fullname.length >= 5 ? '' : 'Your full name must have at least 5 characters'
            this.questionError = this.your_question.length >= 10 ? '' : 'Your question must have at least 10 characters'
            if ( this.nameError || this.questionError ) {
                this.no_errors = false
            } else {
                this.no_errors = true
            }
        }
    }
}
</script>

<style>
form {
    max-width: 400px;
    margin: 20px auto;
    text-align: left;
    padding: 10px;
}

input, select {
    display: block;
    width: 100%;
    color: #333;
    padding: 5px;
    box-sizing: border-box;
}

label {
    display: inline-block;
    margin: 20px 0;
    color: #777;
    font-weight: bold;
}

input[type="checkbox"] {
    width: 20px;
    display: inline-block;
    margin-right: 15px;
}
.btn {
    text-align:center;
}
button {
    padding: 5px;
    margin-top: 10px;
    border-radius: 10px;
}
.error {
    color: red;
    font-size: 0.7em;
    font-style: italic;
}
.success {
    color: green;
    font-size: 0.7em;
    font-style: italic;
}
</style>