<template>
   <h1>Upcoming Seminars</h1>
   <table class="styled-table" v-for="seminar in seminars" :key="seminar.id">
    <td>
    <router-link :v-bind="seminar" :to="{ name: 'SeminarDetails', params: { id: seminar.id   }}"> 
       {{ seminar.title }}
    </router-link>
    </td>
   </table>
</template>

<script>
import seminars from '../seminars.json'
export default {
    data() {
        return {
            seminars
        }
    }
}
</script>

<style>

</style>

