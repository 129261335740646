<template>
  <h1>Seminar Details</h1>
  <div v-for="seminar in seminars" :key="seminar.id">
  <table class="styled-table" v-if="seminar.id == this.$route.params.id">
    <tr>
      <th>id</th>
      <th>title</th>
      <th>time</th>
      <th>location</th>
      <th>description</th>
    </tr>
    <tr>
      <td>{{ id }}</td>
      <td>{{ seminar.title }}</td>
      <td>{{ seminar.time }}</td>
      <td>{{ seminar.location }}</td>
      <td>{{ seminar.description }}</td>
    </tr>
  </table>
  </div>
</template>

<script>
import seminars from '../seminars.json'
export default {
    data() {
        return {
            seminars,
            id: this.$route.params.id,
        }
    }
}
</script>

<style>

</style>