<template>
  <div class="home">
    <h1>Welcome to Know Beer</h1>
    <p>We help you know all about beer, from tasting to building the best in class.</p>
    <p>Come join one of our <a href="#/seminars">seminars</a> for the latest knowledge in beers.</p>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {}
}
</script>
